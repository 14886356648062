<template>
	<div class="homeSet">
		<div class="homeSet-box">
			<div class="box-item">
				<div class="item-title">显示</div>
				<el-switch v-model="open_jielong" active-color="#fa8919"></el-switch>
			</div>
		</div>
		<div class="homeSet-box" v-if="open_jielong == true">
			<div class="box-item">
				<div class="item-title">最大显示条数</div>
				<el-input placeholder="请输入最大显示条数" v-model="jielong_count" type="number">
				</el-input>
			</div>
		</div>
		<div class="homeSet-footer">
			<el-button type="warning" @click.stop="confirmFn">确 定</el-button>
		</div>
	</div>
</template>
   
<script>
export default {
	data() {
		return {
			open_jielong: true,
			jielong_count: ''
		};
	},
	mounted() {
		this.getList()
	},
	methods: {
		getList() {
			this.$http.post(`/erp/v1/home/get_homepage_set`).then((res) => {
				const {
					code,
					data,
					msg
				} = res.data
				this.loading = false
				if (code == 200) {
					data.open_jielong == 0 ? this.open_jielong = false : this.open_jielong = true
					this.jielong_count = data.jielong_count
				} else {
					this.$message.error(msg);
				}
			});
		},
		// 确定
		confirmFn() {
			this.$http.post(`/erp/v1/home/save_topic_jielong`, {
				jielong_count: this.jielong_count,
				open_jielong: this.open_jielong == true ? 1 : 0,
			}).then((res) => {
				const {
					code,
					data,
					msg
				} = res.data
				this.loading = false
				if (code == 200) {
					this.$message.success(msg);
					this.getList()
				} else {
					this.$message.error(msg);
				}
			});
		},
	},
};
</script>
<style lang="scss" scoped>
.homeSet {
	.homeSet-box {
		background: #fff;
		border-radius: 6px;
		padding: 20px;
		margin-bottom: 20px;

		.box-item {
			display: flex;
			align-items: center;

			.item-title {
				width: 100px;
				font-size: 14px;
			}

			.item-b {
				display: flex;
				flex-direction: column;
			}
		}
	}

	.homeSet-footer {
		display: flex;
		justify-content: center;

	}

}
</style>